import React, { useEffect } from 'react';

import NotFound from './NotFound';
import { findProduct } from './products';

import './App.css';

const App = () => {
  // remove "/o/" prefix from url
  const shortId = window.location.pathname.slice(3);
  const product = findProduct(shortId);

  const options = {
    selector: '#widget-anchor',
    place: 'after',
    dataAttributes: {
      ...product,
      sizes: '["all"]',
      standalone: true,
    },
  };

  useEffect(() => {
    if (!product) {
      return;
    }

    if (!window.eyefitu) {
      window.eyefituCalls = [];
      window.eyefitu = function () {
        // @ts-ignore
        window.eyefituCalls.push(arguments);
      };
    }

    window.eyefitu('store', 'oracle-app');
    window.eyefitu('init', undefined, options);
  });

  if (!product) {
    return <NotFound />;
  }

  return <div id="widget-anchor" />;
};

export default App;
