import React from 'react';

import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <span>Product not found</span>
    </div>
  );
};

export default NotFound;
